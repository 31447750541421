import moment from 'moment';

import { FormFieldRecord } from '../jobCreator';

export const patientSchema: FormFieldRecord[] = [
    {
        key: 'nhsNumber',
        followUpCopy: ['ADMIN', 'VISIT'],
        isAnIndex: true,
        transformValue: (values) => {
            return values['nhsNumber'] ? values['nhsNumber'] : '';
        },
    },
    {
        key: 'firstName',
        followUpCopy: ['ADMIN', 'VISIT'],
        transformValue: (values) => {
            return values.firstName && values.firstName !== '' ? values.firstName : '';
        },
    },
    {
        key: 'middleName',
        followUpCopy: ['ADMIN', 'VISIT'],
        transformValue: (values) => {
            return values.middleName && values.middleName !== '' ? values.middleName : '';
        },
    },
    {
        key: 'lastName',
        followUpCopy: ['ADMIN', 'VISIT'],
        transformValue: (values) => {
            return values.lastName && values.lastName !== '' ? values.lastName : '';
        },
    },
    {
        key: 'gender',
        followUpCopy: ['ADMIN', 'VISIT'],
        transformValue: (values) => {
            return values.gender ?? null;
        },
    },
    {
        key: 'dateOfBirth',
        followUpCopy: ['ADMIN', 'VISIT'],
        transformValue: (values) => {
            return values['dateOfBirth'] ? moment(values['dateOfBirth']).toISOString() : null;
        },
    },
    {
        key: 'contactNumber',
        followUpCopy: ['ADMIN', 'VISIT'],
        transformValue: (values) => {
            return values.contactNumber && values.contactNumber !== '' ? values.contactNumber : '';
        },
    },
    {
        key: 'addressLine1',
        followUpCopy: ['ADMIN', 'VISIT'],
        transformValue: (values) => {
            return values.addressLine1 && values.addressLine1 !== '' ? values.addressLine1 : '';
        },
    },
    {
        key: 'addressLine2',
        followUpCopy: ['ADMIN', 'VISIT'],
        transformValue: (values) => {
            return values.addressLine2 && values.addressLine2 !== '' ? values.addressLine2 : '';
        },
    },
    {
        key: 'addressLine3',
        followUpCopy: ['ADMIN', 'VISIT'],
        transformValue: (values) => {
            return values.addressLine3 && values.addressLine3 !== '' ? values.addressLine3 : '';
        },
    },
    {
        key: 'postCode',
        followUpCopy: ['ADMIN', 'VISIT'],
        transformValue: (values) => {
            return values.postCode && values.postCode !== '' ? values.postCode : '';
        },
    },
    {
        key: 'town',
        followUpCopy: ['ADMIN', 'VISIT'],
        transformValue: (values) => {
            return values.town && values.town !== '' ? values.town : '';
        },
    },
    {
        key: 'languagesSpoken',
        followUpCopy: ['ADMIN', 'VISIT'],
        transformValue: (values) => {
            return values.languagesSpoken && values.languagesSpoken.length !== 0
                ? values.languagesSpoken
                : [];
        },
    },
    {
        key: 'staffPreferredGender',
        followUpCopy: ['ADMIN', 'VISIT'],
        transformValue: (values) => {
            return values.staffPreferredGender && values.staffPreferredGender.length !== 0
                ? values.staffPreferredGender
                : [];
        },
    },
    {
        key: 'additionalContactNumbers',
        followUpCopy: ['ADMIN', 'VISIT'],
        transformValue: (values) => {
            return values['additionalContactNumbers']?.filter((str: string) => str) || [];
        },
    },
    {
        key: 'pds',
        followUpCopy: ['ADMIN', 'VISIT'],
        transformValue: (values) => {
            return values['pds']?.versionId ? { versionId: values['pds'].versionId } : null;
        },
    },
];
