import { adminGenericSchema } from './schemas/adminGeneric';
import { genericSchema } from './schemas/generic';
import { patientSchema } from './schemas/patient';
import { referralSchema } from './schemas/referral';
import { visitGenericSchema } from './schemas/visitGeneric';

type VisitType = 'ADMIN' | 'VISIT';

type followUpCopy = VisitType[];

export type valueType =
    | string
    | number
    | null
    | boolean
    | string[]
    | number[]
    | undefined
    | Record<string, string | number | null | boolean | string[] | number[] | undefined>;

export interface FormFieldRecord {
    value?: valueType;
    key: string;
    followUpCopy: followUpCopy;
    transformTo?: string;
    transformValue?: (values: any, typeOfVisit?: VisitType) => any;
    onCreateOnly?: boolean;
    onUpdateOnly?: boolean;
    isAnIndex?: boolean;
}

interface JobCreatorIProps {
    type: VisitType;
    followUp?: boolean;
    values: Record<string, any>;
    onCreate?: boolean;
}

export const adminSchema = [...patientSchema, ...genericSchema, ...adminGenericSchema];
export const visitSchema = [
    ...patientSchema,
    ...referralSchema,
    ...genericSchema,
    ...visitGenericSchema,
];

export const jobCreator = (props: JobCreatorIProps) => {
    if (props.type === 'ADMIN') {
        return doJobCreation({
            type: 'ADMIN',
            values: props.values,
            formFields: adminSchema,
            followUp: props.followUp ?? false,
            onCreate: props.onCreate ?? false,
        });
    } else if (props.type === 'VISIT') {
        return doJobCreation({
            type: 'VISIT',
            values: props.values,
            formFields: visitSchema,
            followUp: props.followUp ?? false,
            onCreate: props.onCreate ?? false,
        });
    }
};

interface CreationIProps {
    followUp: boolean;
    type: VisitType;
    formFields: FormFieldRecord[];
    values: Record<string, any>;
    onCreate?: boolean;
}

export const doJobCreation = (props: CreationIProps) => {
    let schema: Record<string, valueType> = {};

    for (let field of props.formFields) {
        // if it a follow up and we want to copy the fields else continue.
        if (props.followUp && !field.followUpCopy.includes(props.type)) {
            continue;
        }

        // if the field is on create only and it not on create.
        if (field.onCreateOnly && !props.onCreate) {
            continue;
        }

        // certain fields should only be created onUpdate such as abort information, current timestamps so on.
        if (field.onUpdateOnly && props.onCreate) {
            continue;
        }

        schema[field.transformTo ?? field.key] = field.transformValue
            ? field.transformValue(props.values, props.type)
            : props.values[field.key];

        // indexes on create cannot be null, they must either be undefined or a value.
        const isNullorEmptyString =
            schema[field.transformTo ?? field.key] === null ||
            schema[field.transformTo ?? field.key] === '';

        if (field.isAnIndex && isNullorEmptyString && props.onCreate) {
            schema[field.transformTo ?? field.key] = undefined;
        }
    }

    return schema;
};
