import { ADMIN_TIME } from '../../../components/pages/ucr/forms/AdminTime/AdminTimeConsts';
import { FormFieldRecord } from '../jobCreator';

export const adminGenericSchema: FormFieldRecord[] = [
    {
        key: 'disposition',
        followUpCopy: ['ADMIN'],
        transformValue: () => {
            return ADMIN_TIME;
        },
    },
    { key: 'activityType', followUpCopy: ['ADMIN'] },
];
