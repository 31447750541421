import { JobStatus, Patient } from '@doc-abode/data-models';
import { ColorsForJobStatus } from '@doc-abode/helpers';
import moment from 'moment';

import { isMultiAssigneeJob } from '../../../../../helpers/ucr/isMultiAssigneeJob';
import { MarkerData } from '../../../../../interfaces/map';
import { IHcp } from '../../../../../interfaces/ucr';
import { IHCPBreakdown, PatientWithAlert } from '../../../../../stores/MapStoreTypes';
import { getVisitTooltip } from './getTooltipForMaps';
import { Markers } from './Markers';

const ACTIVE_STATES = [JobStatus.ARRIVED, JobStatus.CURRENT];
const INITIALS_UNASSIGNED = 'U';

// TODO - get this from the stylesheets
export function getColourForJobMarker(hasAlerts: boolean, jobStatus?: JobStatus) {
    if (hasAlerts) {
        return '#ff425e';
    }

    let color = '';
    switch (jobStatus) {
        case JobStatus.PENDING:
            color = ColorsForJobStatus.PENDING;
            break;
        case JobStatus.ACCEPTED:
            color = ColorsForJobStatus.ACCEPTED;
            break;
        case JobStatus.AVAILABLE:
            color = ColorsForJobStatus.AVAILABLE;
            break;
        case JobStatus.CURRENT:
            color = ColorsForJobStatus.CURRENT;
            break;
        case JobStatus.ARRIVED:
            color = ColorsForJobStatus.ARRIVED;
            break;
        case JobStatus.HCP_ABORTED:
            color = ColorsForJobStatus.HCP_ABORTED;
            break;
        case JobStatus.CONTROLLER_ABORTED:
            color = ColorsForJobStatus.CONTROLLER_ABORTED;
            break;
        case JobStatus.COMPLETED:
            color = ColorsForJobStatus.COMPLETED;
            break;
        case JobStatus.WITHDRAWN:
            color = ColorsForJobStatus.WITHDRAWN;
            break;
        default:
            color = ColorsForJobStatus.PENDING;
    }
    return color;
}

export function canShowJobLocationOnMap(job: Patient, selectedDate: Date) {
    return Boolean(
        job.latitude &&
            job.longitude &&
            job.dateOfVisit &&
            moment(job.dateOfVisit).diff(selectedDate, 'days') === 0,
    );
}

export function getIconForJobMarker(
    job: Patient,
    initials: Record<string, string>,
    focusedHCPBreakdownOfJobs: Record<string, IHCPBreakdown[]>,
    hasPatientAlerts: boolean,
) {
    const isDoubleUp = isMultiAssigneeJob(job);

    const jobStatus = job.jobStatus;
    const buddyJobStatus = job.buddyJobStatus;
    const hcpId = job.hcpId;
    const buddyId = job.buddyId;

    let fill = getColourForJobMarker(hasPatientAlerts, jobStatus);
    let fillTwo = isDoubleUp ? getColourForJobMarker(hasPatientAlerts, buddyJobStatus) : '';

    let initialsHcp1 = hcpId ? initials[hcpId] : INITIALS_UNASSIGNED;
    let initialsHcp2 = isDoubleUp && buddyId ? initials[buddyId] : INITIALS_UNASSIGNED;

    const isSingleUpActive = ACTIVE_STATES.includes(jobStatus);
    const isDoubleUpActive =
        isDoubleUp &&
        buddyJobStatus &&
        (ACTIVE_STATES.includes(jobStatus) || ACTIVE_STATES.includes(buddyJobStatus));

    let icon = '';

    const foundHcp = focusedHCPBreakdownOfJobs[hcpId || '']?.find(
        (breakDownJob) => breakDownJob.jobId === job.id,
    );

    const foundBuddy = focusedHCPBreakdownOfJobs[buddyId || '']?.find(
        (breakDownJob) => breakDownJob.jobId === job.id,
    );

    if (isDoubleUp) {
        const pinData = {
            fill,
            fillTwo,
            label: initialsHcp1,
            label2: initialsHcp2,
            foundBuddy: initialsHcp1 !== INITIALS_UNASSIGNED,
            foundHcp: initialsHcp2 !== INITIALS_UNASSIGNED,
            buddyPosition: foundBuddy?.position,
            hcpPosition: foundHcp?.position,
        };

        const iconData = isDoubleUpActive
            ? btoa(Markers.doubleActive(pinData))
            : btoa(Markers.double(pinData));
        icon = `data:image/svg+xml;charset=UTF-8;base64, ${iconData}`;
    } else {
        const pinData = {
            fill,
            label: initialsHcp1,
            foundHcp: foundHcp,
            hcpPosition: foundHcp?.position,
        };

        const iconData = isSingleUpActive
            ? btoa(Markers.singleActive(pinData))
            : btoa(Markers.single(pinData));
        icon = `data:image/svg+xml;charset=UTF-8;base64, ${iconData}`;
    }

    return icon;
}

export function prepareMarkerDataForJob(
    job: Patient,
    initials: Record<string, string>,
    focusedHCPBreakdownOfJobs: Record<string, IHCPBreakdown[]>,
    users: IHcp[],
    hasPatientAlerts: boolean,
): MarkerData {
    const latitude = job.latitude ?? 0;
    const longitude = job.longitude ?? 0;

    return {
        type: 'job',
        id: job.id,
        latitude,
        longitude,
        url: getIconForJobMarker(job, initials, focusedHCPBreakdownOfJobs, hasPatientAlerts),
        title: getVisitTooltip(job, users),
        key: `job-marker-${job.id}`,
        clickable: true,
    };
}

export function getQualifyingJobs(
    models: PatientWithAlert[],
    hcpIds: string[],
    selectedDate: Date,
    showUnassignedJobs: boolean,
    overwriteShowUnassigned = false,
): PatientWithAlert[] {
    return models.filter((model) => {
        const job = model.job;

        return (
            canShowJobLocationOnMap(job, selectedDate) &&
            ((showUnassignedJobs && !overwriteShowUnassigned) ||
                (job.hcpId && hcpIds.includes(job.hcpId)) ||
                (job.buddyId && hcpIds.includes(job.buddyId)))
        );
    });
}
