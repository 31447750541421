import moment from 'moment';

import { FormFieldRecord } from '../jobCreator';

export const visitGenericSchema: FormFieldRecord[] = [
    { key: 'postVisitNotesBuddy', followUpCopy: [], onUpdateOnly: true },
    {
        key: 'availableFrom',
        followUpCopy: [],
        transformValue: (values) => {
            if (values.visitDate && values.availableFrom) {
                const availableFromHours = moment(values.availableFrom).get('hours');
                const availableFromMinutes = moment(values.availableFrom).get('minutes');

                return moment(values.visitDate)
                    .clone()
                    .hour(availableFromHours)
                    .minute(availableFromMinutes)
                    .startOf('second')
                    .toISOString();
            }
            return null;
        },
    },
    {
        key: 'availableTo',
        followUpCopy: [],
        transformValue: (values) => {
            if (values.visitDate && values.availableTo) {
                const availableToHours = moment(values.availableTo).get('hours');
                const availableToMinutes = moment(values.availableTo).get('minutes');

                return moment(values.visitDate)
                    .clone()
                    .hour(availableToHours)
                    .minute(availableToMinutes)
                    .startOf('second')
                    .toISOString();
            }
            return null;
        },
    },
    {
        key: 'earliestDateOfVisit',
        followUpCopy: [],
        transformValue(values) {
            if (values.earliestDateOfVisit) {
                return moment(values.earliestDateOfVisit).clone().startOf('second').toISOString();
            }
            return null;
        },
    },
    {
        key: 'referralPathway',
        followUpCopy: ['VISIT'],
    },
    {
        key: 'disposition',
        followUpCopy: ['VISIT'],
    },
    {
        key: 'careComplexity',
        followUpCopy: ['VISIT'],
    },
    {
        key: 'carRequired',
        followUpCopy: ['VISIT'],
        transformValue: (values) => {
            return !values.carRequired ? false : true;
        },
    },
    {
        key: 'madeCurrentDateTime',
        followUpCopy: [],
        transformValue: (values) => {
            return values.madeCurrentDateTime
                ? moment(values.madeCurrentDateTime).toISOString()
                : null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'buddyMadeCurrentDateTime',
        followUpCopy: [],
        transformValue: (values) => {
            return values.buddyMadeCurrentDateTime
                ? moment(values.buddyMadeCurrentDateTime).toISOString()
                : null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'arrivedDateTime',
        followUpCopy: [],
        transformValue: (values) => {
            return values.arrivedDateTime ? moment(values.arrivedDateTime).toISOString() : null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'buddyArrivedDateTime',
        followUpCopy: [],
        transformValue: (values) => {
            return values.buddyArrivedDateTime
                ? moment(values.buddyArrivedDateTime).toISOString()
                : null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'buddyHcpAbortedDateTime',
        followUpCopy: [],
        transformValue: (values) => {
            return values.buddyHcpAbortedDateTime
                ? moment(values.buddyHcpAbortedDateTime).toISOString()
                : null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'hcpAbortedDateTime',
        followUpCopy: [],
        transformValue: (values) => {
            return values.hcpAbortedDateTime
                ? moment(values.hcpAbortedDateTime).toISOString()
                : null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'buddyFinishedDateTime',
        followUpCopy: [],
        transformValue: (values) => {
            return values.buddyFinishedDateTime
                ? moment(values.buddyFinishedDateTime).toISOString()
                : null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'finishedDateTime',
        followUpCopy: [],
        transformValue: (values) => {
            return values.finishedDateTime ? moment(values.finishedDateTime).toISOString() : null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'buddyControllerAbortedNotes',
        followUpCopy: [],
        transformValue: (values) => {
            return values.buddyControllerAbortedNotes ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'buddyControllerAbortedReason',
        followUpCopy: [],
        transformValue: (values) => {
            return values.buddyControllerAbortedReason ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'buddyControllerReverseNotes',
        followUpCopy: [],
        transformValue: (values) => {
            return values.buddyControllerReverseNotes ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'buddyControllerReverseReason',
        followUpCopy: [],
        transformValue: (values) => {
            return values.buddyControllerReverseReason ?? null;
        },
        onUpdateOnly: true,
    },

    {
        key: 'buddyHcpAbortedNotes',
        followUpCopy: [],
        transformValue: (values) => {
            return values.buddyHcpAbortedNotes ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'buddyHcpAbortedReason',
        followUpCopy: [],
        transformValue: (values) => {
            return values.buddyHcpAbortedReason ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'buddyHcpReverseNotes',
        followUpCopy: [],
        transformValue: (values) => {
            return values.buddyHcpReverseNotes ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'buddyHcpReverseReason',
        followUpCopy: [],
        transformValue: (values) => {
            return values.buddyHcpReverseNotes ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'controllerAbortedNotes',
        followUpCopy: [],
        transformValue: (values) => {
            return values.controllerAbortedNotes ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'controllerAbortedReason',
        followUpCopy: [],
        transformValue: (values) => {
            return values.controllerAbortedReason ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'controllerReverseNotes',
        followUpCopy: [],
        transformValue: (values) => {
            return values.controllerReverseNotes ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'controllerReverseReason',
        followUpCopy: [],
        transformValue: (values) => {
            return values.controllerReverseReason ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'hcpAbortedNotes',
        followUpCopy: [],
        transformValue: (values) => {
            return values.hcpAbortedNotes ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'hcpAbortedReason',
        followUpCopy: [],
        transformValue: (values) => {
            return values.hcpAbortedReason ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'hcpReverseNotes',
        followUpCopy: [],
        transformValue: (values) => {
            return values.hcpReverseNotes ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'hcpReverseReason',
        followUpCopy: [],
        transformValue: (values) => {
            return values.hcpReverseReason ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'jobStatusBeforeAbort',
        followUpCopy: [],
        transformValue: (values) => {
            return values.jobStatusBeforeAbort ?? null;
        },
        onUpdateOnly: true,
    },
    {
        key: 'buddyJobStatusBeforeAbort',
        followUpCopy: [],
        transformValue: (values) => {
            return values.buddyJobStatusBeforeAbort ?? null;
        },
        onUpdateOnly: true,
    },
];
