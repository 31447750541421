import { NetworkStatus, useLazyQuery } from '@apollo/client';
import { JobStatus, JobType, Patient } from '@doc-abode/data-models';
import cn from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import { FC, useCallback, useContext, useEffect } from 'react';
import { Redirect, Switch, useParams } from 'react-router';

import { JobFilter } from '../../../../__generated__/v2';
import { ViewToShow } from '../../../../constants/mainConst';
import { GET_ALL_WARNINGS, QUERY_JOBS_BY_JOB_TYPE } from '../../../../graphql/queries/jobs';
import { SentryRoute } from '../../../../helpers/sentryRoute';
import { getFilteredHCPs, getFilteredJobs, getJobsForLinkedVisits } from '../../../../helpers/ucr';
import getHcpTypes from '../../../../helpers/ucr/getHcpTypes';
import useStores from '../../../../hook/useStores';
import { IHcp, IHcpType } from '../../../../interfaces/ucr';
import { JobsContext } from '../../../../providers';
import RootStore from '../../../../stores/RootStore';
import { TFocusedUser } from '../../../../stores/UCRStore';
import { getStartAndEndDay } from '../../../modules/helpers/formatDataTyped';
import { graphqlErrorHandler } from '../../common/errorHandling/graphqlErrorHandler';
import { Calendar, ListView } from '../components';
import MapController from '../components/mapView/MapController';
import PatientListLegacy from '../components/patientView/PatientList';
import S1Messages from '../components/s1MessagesView/S1Messages';
import UcrFilters from '../views/UcrFilters';
import { FetchJobsContext } from './MainConst';
import { PanelDetails } from './panels';

interface IProps {}

const v2Filter = (start: string, end: string): string => {
    const filter: JobFilter[] = [
        {
            plannedStartDateTime: {
                gte: start,
                lte: end,
            },
            assignmentStatusKey: {
                not: JobStatus.PENDING,
            },
            dynamoDBIndexName: 'jobType-index',
            jobType: {
                equals: JobType.UCR,
            },
        },
        {
            assignmentStatusKey: {
                equals: JobStatus.PENDING,
            },
            dynamoDBIndexName: 'jobType-index',
            jobType: {
                equals: JobType.UCR,
            },
        },
    ];

    return JSON.stringify(filter);
};

const Main: FC<IProps> = () => {
    const {
        RootStore: {
            ucrStore: {
                hcpFilters,
                jobFilters,
                nameFilters,
                selectedDate,
                jobs,
                warnings,
                setHcps,
                setJobs,
                setLoadingJobs,
                loadingJobs,
                loadingWarnings,
                setWarnings,
                setLoadingWarnings,
                fetchAlerts,
                setHcpTypes,
                patientAlerts,
                staffAlerts,
                viewToShow,
                pinnedStaff,
                focusedJobId,
            },
            s1Store: { fetchLinkedVisits },
            usersStore: { hcpUsers: users, getUsers, getHcpFunctions },
            schedulesStore: { setDate, allSchedules, fetchSchedules },
            lovsStore: { hcpType },
            configStore: { isFeatureEnabled },
        },
    } = useStores<{ RootStore: RootStore }>();

    const jobsContext = useContext(JobsContext);
    const POLL_INTERVAL = 60000;

    const [loadJobs, { data, refetch, networkStatus }] = useLazyQuery(QUERY_JOBS_BY_JOB_TYPE, {
        fetchPolicy: 'network-only',
        pollInterval: POLL_INTERVAL,
        onError: ({ graphQLErrors }) => graphqlErrorHandler({ graphQLErrors }),
        onCompleted: () => {
            // Note this only runs on first query, see discussion here: https://github.com/apollographql/apollo-client/issues/5531
            // quick fix for VSU-2432, as the data refresh is now aware of jobsContext.refreshAssignedJobs
            jobsContext.setRefreshAssignedJobs(true);
        },
    });
    const [loadWarnings, { refetch: refetchWarnings, networkStatus: warningNetworkStatus }] =
        useLazyQuery(GET_ALL_WARNINGS, {
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                setWarnings(data.getWarnings.allWarnings);
            },
        });

    // syncing of s1 linked visits, runs on an interval provided that s1 is Enabled and jobs are fetched.
    const syncS1Status = useCallback(() => {
        if (!isFeatureEnabled('s1Enabled')) {
            return;
        }

        const jobs = data?.queryJobsByJobTypeIndex?.items;

        if (!jobs?.length) {
            return;
        }

        const jobsForLinkedVisits = getJobsForLinkedVisits(jobs);
        fetchLinkedVisits(jobsForLinkedVisits);
    }, [data, fetchLinkedVisits, isFeatureEnabled]);

    useEffect(() => {
        const timer = setInterval(() => syncS1Status(), POLL_INTERVAL);
        return () => clearInterval(timer);
    }, [syncS1Status]);

    useEffect(() => {
        syncS1Status();
    }, [selectedDate, syncS1Status, data]);

    useEffect(() => {
        if (jobsContext.refreshAssignedJobs) {
            refetch?.();
        }
    }, [jobsContext.refreshAssignedJobs, refetch]);

    useEffect(() => {
        fetchAlerts();
    }, [fetchAlerts, jobs]);
    useEffect(() => {
        loadWarnings({
            variables: {
                warning: {
                    dateOfVisit: new Date(selectedDate.setMilliseconds(0)),
                },
            },
        });
    }, [loadingJobs, loadWarnings, jobs, selectedDate]);
    useEffect(() => {
        const isLoading =
            networkStatus === NetworkStatus.loading ||
            networkStatus === NetworkStatus.refetch ||
            networkStatus === NetworkStatus.setVariables;
        setLoadingJobs(isLoading);
    }, [networkStatus, setLoadingJobs]);
    useEffect(() => {
        const isLoading =
            warningNetworkStatus === NetworkStatus.loading ||
            warningNetworkStatus === NetworkStatus.refetch ||
            warningNetworkStatus === NetworkStatus.setVariables;
        if (isLoading !== loadingWarnings) {
            setLoadingWarnings(isLoading);
        }
    }, [warningNetworkStatus, setLoadingWarnings, loadingWarnings]);
    useEffect(() => {
        const [start, end] = getStartAndEndDay(selectedDate);
        setDate(selectedDate);
        loadJobs({
            variables: {
                jobType: JobType.UCR,
                filter: {
                    or: [
                        // Administrative
                        {
                            startDateTime: {
                                between: [start, end],
                            },
                            disposition: {
                                eq: 'admin',
                            },
                        },
                        // Assigned visit
                        {
                            startDateTime: {
                                between: [start, end],
                            },
                            or: [
                                {
                                    jobStatus: {
                                        ne: JobStatus.PENDING,
                                    },
                                },
                                {
                                    buddyJobStatus: {
                                        ne: JobStatus.PENDING,
                                    },
                                },
                            ],
                        },
                        // Unassigned visit
                        {
                            jobStatus: {
                                eq: JobStatus.PENDING,
                            },
                        },
                        // Unassigned buddy for double up visit
                        {
                            and: [
                                {
                                    buddyJobStatus: {
                                        eq: JobStatus.PENDING,
                                    },
                                },
                                {
                                    staffRequired: {
                                        eq: 2,
                                    },
                                },
                            ],
                        },
                    ],
                },
                v2Filter: v2Filter(start, end),
            },
        });
        loadWarnings({
            variables: {
                warning: {
                    dateOfVisit: selectedDate,
                },
            },
        });
    }, [selectedDate, loadJobs, setDate, loadWarnings]);
    // Filtering and saving HCPs
    useEffect(() => {
        const filteredHCPs: IHcp[] = getFilteredHCPs({
            users,
            jobsFromStore: jobs,
            hcpFilters,
            nameFilters,
            allSchedules,
            patientAlerts,
            staffAlerts,
            selectedDate,
            staffPinned: pinnedStaff,
        });
        setHcps(filteredHCPs);
    }, [
        users,
        hcpFilters,
        selectedDate,
        jobs,
        allSchedules,
        setHcps,
        patientAlerts,
        staffAlerts,
        pinnedStaff,
        nameFilters,
    ]);
    // Saving all Jobs
    useEffect(() => {
        // the quick fix for VSU-2432 may mean this runs more times than necessary
        // but the running of the effect is now aware of jobsContext.refreshAssignedJobs
        // so it will trigger when the data changes and if we have jobsContext.refreshAssignedJobs
        // seem to fix the issue, not sure of wider context though.
        setJobs(data?.queryJobsByJobTypeIndex?.items?.map((job: Patient) => new Patient(job)));
        if (jobsContext.refreshAssignedJobs) {
            jobsContext.setRefreshAssignedJobs(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, setJobs, jobsContext.refreshAssignedJobs]);

    // Saving filtered Jobs
    useEffect(() => {
        const filteredJobs: Patient[] = getFilteredJobs({
            jobs,
            jobFilters,
            nameFilters,
            warnings,
            patientAlerts,
            viewToShow,
        });
        setJobs(filteredJobs, true);
    }, [
        users,
        hcpFilters,
        selectedDate,
        jobs,
        allSchedules,
        setJobs,
        jobFilters,
        warnings,
        patientAlerts,
        staffAlerts,
        viewToShow,
        nameFilters,
    ]);

    useEffect(() => {
        const hcpTypes: IHcpType[] = getHcpTypes(users, hcpType);
        setHcpTypes(hcpTypes);
    }, [users, setHcpTypes, hcpType]);

    useEffect(() => {
        getHcpFunctions(moment(selectedDate).format('YYYY-MM-DD'));
    }, [selectedDate, getHcpFunctions]);

    const refreshJobs = () => {
        if (refetch) {
            refetch();
            fetchSchedules();
            getUsers();
            getHcpFunctions(moment(selectedDate).format('YYYY-MM-DD'));
        }
        if (refetchWarnings) {
            refetchWarnings();
        }
        fetchAlerts();
        syncS1Status();
    };

    const fetchSchedulesAndWarnings = async () => {
        if (loadJobs) {
            const [start, end] = getStartAndEndDay(selectedDate);
            loadJobs({
                variables: {
                    jobType: JobType.UCR,
                    filter: {
                        or: [
                            // Administrative
                            {
                                startDateTime: {
                                    between: [start, end],
                                },
                                disposition: {
                                    eq: 'admin',
                                },
                            },
                            // Assigned visit
                            {
                                dateOfVisit: {
                                    between: [start, end],
                                },
                                or: [
                                    {
                                        jobStatus: {
                                            ne: JobStatus.PENDING,
                                        },
                                    },
                                    {
                                        buddyJobStatus: {
                                            ne: JobStatus.PENDING,
                                        },
                                    },
                                ],
                            },
                            // Unassigned visit
                            {
                                jobStatus: {
                                    eq: JobStatus.PENDING,
                                },
                            },
                            // Unassigned buddy for double up visit
                            {
                                and: [
                                    {
                                        buddyJobStatus: {
                                            eq: JobStatus.PENDING,
                                        },
                                    },
                                    {
                                        staffRequired: {
                                            eq: 2,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    v2Filter: v2Filter(start, end),
                },
            });
            fetchSchedules();
        }
        if (refetchWarnings) {
            refetchWarnings();
        }
        fetchAlerts();
    };

    return (
        <article className="ucr__main">
            <UcrFilters refreshJobs={refreshJobs} />
            <div className={cn('ucr__timeline', { 'ucr__timeline--split': focusedJobId })}>
                <UCRRouting
                    fetchSchedulesAndWarnings={fetchSchedulesAndWarnings}
                    refreshJobs={refreshJobs}
                />
                {focusedJobId && <PanelDetails refreshJobs={refreshJobs} />}
            </div>
        </article>
    );
};

const SetViewToShow: FC<{ viewToShow: ViewToShow }> = observer(({ viewToShow }) => {
    const { jobId, user } = useParams<{
        jobId: string;
        // todo check that TFocusedUser are the only possible options passe in params.
        user?: TFocusedUser;
    }>();

    const {
        RootStore: {
            ucrStore: { setViewToShow, setFocused },
        },
    } = useStores<{ RootStore: RootStore }>();

    useEffect(() => {
        setViewToShow(viewToShow);
    }, [setViewToShow, viewToShow]);

    useEffect(() => {
        setFocused({ jobId, user });
    }, [jobId, user, setFocused]);

    return null;
});

const UCRRouting: FC<any> = observer(({ fetchSchedulesAndWarnings, refreshJobs }) => {
    return (
        <Switch>
            <Redirect exact from="/scheduling" to="/scheduling/timeline" />

            <SentryRoute path="/scheduling/listview/:jobId?/:user?">
                <SetViewToShow viewToShow={ViewToShow.VISITS_LIST} />
                <ListView />
            </SentryRoute>

            <SentryRoute path="/scheduling/mapview/:jobId?/:user?">
                <SetViewToShow viewToShow={ViewToShow.MAP} />
                <MapController />
            </SentryRoute>

            <SentryRoute path="/scheduling/timeline/:jobId?/:user?">
                <SetViewToShow viewToShow={ViewToShow.TIMELINE} />
                <FetchJobsContext.Provider value={{ fetchSchedulesAndWarnings, refreshJobs }}>
                    <Calendar />
                </FetchJobsContext.Provider>
            </SentryRoute>

            <SentryRoute path="/scheduling/patients/:jobId?/:user?">
                <SetViewToShow viewToShow={ViewToShow.PATIENTS_LIST} />
                <PatientListLegacy />
            </SentryRoute>

            <SentryRoute path="/scheduling/systmone-messages">
                <SetViewToShow viewToShow={ViewToShow.S1_MESSAGES} />
                <S1Messages />
            </SentryRoute>
        </Switch>
    );
});

export default observer(Main);
