import { TextArea } from '@blueprintjs/core';
import { Patient } from '@doc-abode/data-models';
import { useState } from 'react';

import { ConditionalDisplay } from '../../../../../CondtionalDisplay';
import Loader from '../../../../../modules/helpers/Loader';
import { Button, ButtonColors, ButtonSizes } from '../../../../../v2/components';
import { useApolloActions } from '../../../actions/useApolloActions';

interface IParams {
    job: Patient;
    setEditValue: React.Dispatch<React.SetStateAction<string | null | undefined>>;
    setEditMode: React.Dispatch<React.SetStateAction<string | null | undefined>>;
    editValue: string | null | undefined;
    fieldName: string;
}

export const QuickEditTextArea = (params: IParams) => {
    const { fieldName, editValue, setEditValue, setEditMode, job } = params;

    const [loading, setLoading] = useState(false);

    const { actionsGetJob, actionsUpdateJob } = useApolloActions({ jobId: job.id });

    return (
        <div className="info-panel-textarea__container">
            <TextArea
                autoFocus={true}
                className="v2__form-textarea-input"
                fill={true}
                onChange={(e) => setEditValue(e.target.value)}
            >
                {editValue}
            </TextArea>
            <ConditionalDisplay
                show={actionsGetJob.isLoading || actionsUpdateJob.isLoading || loading}
            >
                <div className="info-panel-textarea__loading">
                    <Loader fullscreen={false} size={24} />
                </div>
            </ConditionalDisplay>
            <ConditionalDisplay
                show={!actionsGetJob.isLoading && !actionsUpdateJob.isLoading && !loading}
            >
                <div className="info-panel-textarea__buttons">
                    <Button
                        size={ButtonSizes.SMALL}
                        color={ButtonColors.RED}
                        name={'Cancel'}
                        clickEvent={() => {
                            setEditMode(null);
                            setEditValue(null);
                            setLoading(false);
                        }}
                    />
                    <Button
                        size={ButtonSizes.SMALL}
                        name={'Accept'}
                        clickEvent={async () => {
                            setLoading(true);
                            await actionsUpdateJob.updateJobOperation({
                                variables: {
                                    input: {
                                        id: job.id,
                                        [fieldName]: editValue?.trim() ?? '',
                                        version: job.version + 1,
                                    },
                                },
                            });

                            await actionsGetJob.getJobByIdRefetch();
                            setEditMode(null);
                            setLoading(false);
                        }}
                    />
                </div>
            </ConditionalDisplay>
        </div>
    );
};
