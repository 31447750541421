import { useLazyQuery, useMutation } from '@apollo/client';

import { GET_JOB_BY_ID, UPDATE_JOB } from '../../../../graphql/queries/jobs';

interface IParams {
    jobId?: string;
}

export const useApolloActions = (params: IParams) => {
    const [
        getJobByIdOperation,
        {
            refetch: getJobByIdRefetch,
            networkStatus: getJobByIdNetworkStatus,
            loading: getJobLoading,
        },
    ] = useLazyQuery(GET_JOB_BY_ID, {
        fetchPolicy: 'network-only',
        variables: {
            id: params.jobId,
        },
    });

    const [updateJobOperation, { loading: updateJobLoading }] = useMutation(UPDATE_JOB);

    return {
        actionsGetJob: {
            getJobByIdOperation,
            getJobByIdRefetch,
            getJobByIdNetworkStatus,
            isLoading: getJobLoading,
        },
        actionsUpdateJob: {
            updateJobOperation,
            isLoading: updateJobLoading,
        },
    };
};
