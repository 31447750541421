import { JobStatus, Patient } from '@doc-abode/data-models';
import { formatNameLastMiddleFirst } from '@doc-abode/helpers';
import moment from 'moment';

import { genderMapping, statusTags } from '../../../../../constants/mainConst';
import { nhsNumberFormatter } from '../../../../../helpers';
import getHcpName from '../../../../../helpers/ucr/getHcpName';
import { getPathwayServiceOrAdminTypeLabels } from '../../../../../helpers/ucr/getPathwayServiceOrAdminTypeLabels';
import { isMultiAssigneeJob } from '../../../../../helpers/ucr/isMultiAssigneeJob';
import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';
import { getAgeFromDate } from '../../../../modules/helpers/formatData';
import { useView } from '../../views/useView';
import { IPatientExtended } from './ListView';

function getGender(gender: string | null | undefined): string | null {
    if (!gender) {
        return '';
    }

    return genderMapping[gender];
}

function getDateOfBirth(date: string | null | undefined): string | null {
    if (!date) {
        return '';
    }
    return `${moment(date).format('DD/MM/YYYY')} (Age: ${getAgeFromDate(date)})`;
}

/**
 * NOTE: Logic assumes that fields for dbl-ups have been mapped outside this FC; see ListView ~line 149 for details
 * @param job
 * @returns
 */
export const useListViewRowViewModel = (job: IPatientExtended) => {
    const {
        RootStore: {
            configStore: { pathways, adminTypes },
            ucrStore: { focusedJobId, setFocused },
            usersStore: { users },
        },
    } = useStores<{ RootStore: RootStore }>();
    const { openDeepLink } = useView();

    // sets the focus of a patient, highlights the row and amends the listview history.
    const focusJob = (id: string, isBuddyRow: boolean): void => {
        const userId = isBuddyRow ? 'user2' : 'user1';
        setFocused({ jobId: id, user: userId });
        openDeepLink(id, userId);
    };

    const hasStaffAlert = job.hasStaffAlert ? 'ucr-listview__list-row--staff_alert' : '';
    const hasPatientAlert = job.hasPatientAlert ? 'ucr-listview__list-row--patient_alert' : '';

    const focused =
        focusedJobId === job.id
            ? 'ucr-listview__containerWidth ucr-listview__list-row ucr-listview__list-row--content ucr-listview__list-row--focused'
            : `ucr-listview__containerWidth ucr-listview__list-row ucr-listview__list-row--content ${
                  hasStaffAlert || hasPatientAlert
              }`;

    const jobStatus = job.jobStatus || JobStatus.PENDING;

    const { pathwayDisplayLabel, serviceOrAdminTypeDisplayLabel } =
        getPathwayServiceOrAdminTypeLabels(job, pathways, adminTypes);

    return {
        focusJob,
        focused,
        pressed: focusedJobId === job.id,
        isBuddyRow: isMultiAssigneeJob(job) && job.hcpId === job.buddyId,
        nhsNumber: nhsNumberFormatter(job.nhsNumber),
        patientName: formatNameLastMiddleFirst(job),
        dateOfBirthAge: getDateOfBirth(job.dateOfBirth),
        gender: getGender(job.gender),
        postCode: job.postCode,
        pathway: pathwayDisplayLabel,
        service: serviceOrAdminTypeDisplayLabel,
        plannedStartDateTime: job.startDateTime,
        plannedDuration: job.duration,
        arrivedDateTime: job.arrivedDateTime,
        finishedOrAbortedDateTime:
            JobStatus.HCP_ABORTED === jobStatus ? job.hcpAbortedDateTime : job.finishedDateTime,
        hcpName: getHcpName(users, job.hcpId, false),
        jobStatus: Patient.getFriendlyVisitStatus(jobStatus as JobStatus),
        jobStatusClassname: `ucr-listview__status job-status-background__${statusTags[jobStatus]}`,
    };
};
