import { LiveLocationThreshold } from '@doc-abode/data-models';
import { useJsApiLoader } from '@react-google-maps/api';
import { ClusterIconStyle } from '@react-google-maps/marker-clusterer';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { GOOGLE_API_LIBRARIES } from '../../../../../constants/googleAPIs';
import useStores from '../../../../../hook/useStores';
import { MarkerData } from '../../../../../interfaces/map';
import { IHcpExtended } from '../../../../../interfaces/ucr';
import { PatientWithAlert } from '../../../../../stores/MapStoreTypes';
import RootStore from '../../../../../stores/RootStore';
import { useView } from '../../views/useView';
import {
    IBaseMarkerForMap,
    prepareMarkerDataForBaseLocation,
    shouldShowBaseMarker,
} from './getBaseMarker';
import {
    getHcpsWithQualifyingLiveLocations,
    prepareMarkerDataForHcpLiveLocation,
    shouldShowLiveLocationsOnMap,
} from './getHcpLiveLocationMarkers';
import { getQualifyingJobs, prepareMarkerDataForJob } from './getJobMarkers';
import { Markers } from './Markers';

export const ClusterColor = '#546e7a';

export const useMapContainerViewModel = () => {
    const { clearDeepLink, openDeepLink } = useView();
    const {
        RootStore: {
            mapStore: {
                hcps: mapHcps,
                hcpsBreakdownInfo,
                showUnassignedJobs,
                boundedArea,
                setBoundedArea,
                loadingJobs,
                resetHCPBreakdown,
                assignedJobsFiltered: assignedJobs,
                unassignedJobsFilter: unassignedJobs,
                focusedHCPBreakdownOfJobs,
            },
            configStore: {
                mapZoomLevel,
                isFeatureEnabled,
                liveLocationThreshold,
                mapCentre,
                clientKeys,
            },
            usersStore: { users },
            ucrStore: { selectedDate },
        },
    } = useStores<{ RootStore: RootStore }>();

    const intervalData = liveLocationThreshold as LiveLocationThreshold;

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: clientKeys.googleMaps,
        libraries: GOOGLE_API_LIBRARIES,
    });
    const [loadedMap, setLoadedMap] = useState<any>({});
    const [currentDate, setCurrentDate] = useState<number>(0);

    // Support showing the org's base as marker on the Map
    const { showBase, baseMarkerData } = useMemo(() => {
        const baseLocation = mapCentre as IBaseMarkerForMap;

        const showBase = shouldShowBaseMarker(baseLocation);

        const baseMarkerData = showBase
            ? prepareMarkerDataForBaseLocation(baseLocation)
            : undefined;

        return { showBase, baseMarkerData };
    }, [mapCentre]);

    // Support showing of live location data
    const { shouldShowLiveLocations, liveLocationMarkerData } = useMemo(() => {
        let shouldShowLiveLocations = shouldShowLiveLocationsOnMap(
            isFeatureEnabled('hcpLiveLocation'),
            selectedDate,
        );
        let hcpsWithLiveLocations: IHcpExtended[] = [];
        let liveLocationMarkerData: MarkerData[] = [];

        if (shouldShowLiveLocations) {
            hcpsWithLiveLocations = getHcpsWithQualifyingLiveLocations(
                mapHcps,
                hcpsBreakdownInfo,
                intervalData,
            );
        }

        hcpsWithLiveLocations.forEach((hcp) => {
            liveLocationMarkerData.push(prepareMarkerDataForHcpLiveLocation(hcp, intervalData));
        });

        return {
            shouldShowLiveLocations,
            hcpsWithLiveLocations,
            liveLocationMarkerData,
        };
    }, [selectedDate, mapHcps, hcpsBreakdownInfo, isFeatureEnabled, intervalData]);

    let maps: any;
    let bounds: any;

    if (isLoaded) {
        maps = (window as any).google.maps;
    }

    const userInitials: Record<string, string> = useMemo(() => {
        let initials: Record<string, string> = {};

        users.forEach((user) => {
            initials[user.userId] = user.firstName.charAt(0) + '' + user.lastName.charAt(0);
        });

        return initials;
    }, [users]);

    const hcpIds = useMemo(() => {
        return mapHcps
            .filter(({ userId }: { userId: string }) => !!hcpsBreakdownInfo[userId])
            .map(({ userId }: { userId: string }) => userId);
    }, [mapHcps, hcpsBreakdownInfo]);

    const jobs: PatientWithAlert[] = useMemo(() => {
        const jobs = [];

        jobs.push(
            ...getQualifyingJobs(assignedJobs, hcpIds, selectedDate, showUnassignedJobs, true),
        );
        jobs.push(...getQualifyingJobs(unassignedJobs, hcpIds, selectedDate, showUnassignedJobs));

        return jobs;
    }, [assignedJobs, hcpIds, selectedDate, showUnassignedJobs, unassignedJobs]);

    const jobPins: MarkerData[] = useMemo(() => {
        return jobs.map((model) => {
            const job = model.job;

            return prepareMarkerDataForJob(
                job,
                userInitials,
                focusedHCPBreakdownOfJobs,
                users,
                model.hasPatientAlert,
            );
        });
    }, [jobs, userInitials, focusedHCPBreakdownOfJobs, users]);

    const allMarkers = useMemo(() => {
        const allMarkers = [...jobPins];
        if (showBase && baseMarkerData) {
            allMarkers.push(baseMarkerData);
        }

        if (shouldShowLiveLocations && liveLocationMarkerData.length > 0) {
            allMarkers.push(...liveLocationMarkerData);
        }

        return allMarkers;
    }, [baseMarkerData, jobPins, liveLocationMarkerData, shouldShowLiveLocations, showBase]);

    // Setting the boundaries of the Map View
    if (maps) {
        bounds = new maps.LatLngBounds();

        allMarkers.forEach((marker) => {
            bounds.extend({ lat: marker.latitude, lng: marker.longitude });
        });
    }

    useEffect(() => {
        if (
            maps &&
            loadedMap?.fitBounds &&
            jobPins.length > 0 &&
            !loadingJobs &&
            moment(selectedDate).valueOf() !== currentDate
        ) {
            loadedMap.fitBounds(bounds);
            setCurrentDate(moment(selectedDate).valueOf());
        }
        if (loadingJobs) {
            loadedMap.fitBounds && loadedMap.fitBounds(bounds);
        }
    }, [
        loadedMap,
        loadedMap?.fitBounds,
        jobPins.length,
        selectedDate,
        maps,
        bounds,
        currentDate,
        loadingJobs,
    ]);

    const notLoaded = !isLoaded;

    // Handles date changes and clicking RESET ZOOM
    useEffect(() => {
        if (
            maps &&
            loadedMap &&
            !notLoaded &&
            loadedMap.fitBounds &&
            jobPins.length >= 1 &&
            moment(selectedDate).valueOf() !== currentDate
        ) {
            resetHCPBreakdown();
            loadedMap.fitBounds(bounds);
            setCurrentDate(moment(selectedDate).valueOf());
        }
    }, [
        notLoaded,
        loadedMap,
        jobPins.length,
        selectedDate,
        maps,
        bounds,
        currentDate,
        resetHCPBreakdown,
    ]);

    // Handles the additional job number overlay when expanding the HCP panel for a given HCP
    useEffect(() => {
        if (Object.keys(focusedHCPBreakdownOfJobs).length > 0 && maps && loadedMap?.fitBounds) {
            const bounding = new maps.LatLngBounds();
            let bounded = false;
            Object.values(focusedHCPBreakdownOfJobs).forEach((breakdown) => {
                breakdown.forEach((jobList) => {
                    if (jobList.latitude && jobList.longitude) {
                        bounding.extend({ lat: jobList.latitude, lng: jobList.longitude });
                        bounded = true;
                    }
                });
            });

            if (bounded) {
                loadedMap.fitBounds(bounding);
            }
        }
    }, [focusedHCPBreakdownOfJobs, loadedMap, maps, maps?.LatLngBounds]);

    // Handles the click on a job within the job breakdown in the HCP panel
    useEffect(() => {
        if (boundedArea && maps) {
            if (boundedArea.latitude && boundedArea.longitude) {
                loadedMap.setZoom(19);
                loadedMap.setCenter({
                    lat: boundedArea.latitude - 0.0002,
                    lng: boundedArea.longitude,
                });
            }
            if (boundedArea.jobId) {
                openDeepLink(boundedArea.jobId, !boundedArea.isBuddy ? 'user1' : 'user2');
            }

            setBoundedArea(undefined);
        }
    }, [
        boundedArea,
        maps,
        loadedMap,
        loadedMap?.setCenter,
        loadedMap?.setZoom,
        setBoundedArea,
        openDeepLink,
    ]);

    const onLoad = useCallback(function callback(map: any) {
        setLoadedMap(map);
    }, []);

    const generateImageOptions = () => {
        const icon = `data:image/svg+xml;charset=UTF-8;base64, ${btoa(
            Markers.cluster({
                fill: ClusterColor,
                label: '',
            }),
        )}`;

        const style: ClusterIconStyle[] = [];

        for (let i = 0; i < 5; i++) {
            style.push({
                textColor: 'white',
                url: icon,
                height: 40,
                width: 32,
                anchorText: [-2, 0],
                backgroundPosition: '0 0',
            });
        }

        return style;
    };

    const gridSize = useMemo(() => {
        return Object.keys(focusedHCPBreakdownOfJobs).length >= 1 ? 0 : 10;
    }, [focusedHCPBreakdownOfJobs]);

    return {
        onLoad,
        clearDeepLink,
        openDeepLink,
        generateImageOptions,
        mapZoomLevel,
        bounds,
        loadedMap,
        allMarkers,
        notLoaded,
        gridSize,
    };
};
