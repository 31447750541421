import { FormikContextType, FormikValues, useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';

import { AccordionColors } from '../../../../v2/components';
import { FormMode, FormSteps } from '../common';
import { getStepSchema } from './validation';

interface IProps {
    step: FormSteps;
    formMode: FormMode;
    onSubmit: (values: FormikValues) => Promise<void>;
}
export const useAddVisitFormViewModel = ({ step, formMode, onSubmit }: IProps) => {
    const [currentStep, setCurrentStep] = useState<FormSteps>(step);
    const [stepsCompleted, setStepsCompleted] = useState<string[]>([]);

    const { values, setFieldValue, setTouched, setValues }: FormikContextType<FormikValues> =
        useFormikContext();
    const onNextStep = useCallback(
        (nextStep: FormSteps, prevStep: FormSteps) => {
            const stepSchema = getStepSchema(prevStep);
            const stepFields = Object.keys(stepSchema.fields).reduce(
                (fields, field) => ({ ...fields, [field]: true }),
                {},
            );

            setTouched(stepFields, false);

            try {
                if (!stepSchema.validateSync(values)) return;

                if (nextStep === FormSteps.REVIEW) {
                    onSubmit(values);
                    return true;
                } else {
                    setStepsCompleted([...stepsCompleted, prevStep]);
                    setCurrentStep(nextStep);
                    return true;
                }
            } catch (err: any) {
                console.log('Validation error:', err.message);
                return false;
            }
        },
        [onSubmit, stepsCompleted, values, setTouched],
    );

    const onPatientDetailsNextStep = useCallback(() => {
        if (formMode === FormMode.DEFAULT || formMode === FormMode.FOLLOW_UP) {
            onNextStep(FormSteps.REFERRAL, FormSteps.PATIENT);
        } else if (formMode === FormMode.ADD_VISIT) {
            onNextStep(FormSteps.REVIEW, FormSteps.PATIENT);
        }
    }, [formMode, onNextStep]);

    const setStep = (step: FormSteps) => {
        // validate the current step.
        const stepSchema = getStepSchema(currentStep);
        try {
            // validation includes patient or referral we don't block.
            // if a step is previously completed we allow them to switch too that.

            const stepFields = Object.keys(stepSchema.fields).reduce(
                (fields, field) => ({ ...fields, [field]: true }),
                {},
            );

            setTouched(stepFields, true);

            if (stepSchema.validateSync(values)) {
                setCurrentStep(step);
                return true;
            }

            if (stepsCompleted.includes(step)) {
                setCurrentStep(step);
                return true;
            }
        } catch (err: any) {
            return false;
        }
    };

    useEffect(() => {
        // because it completely conditional field we should step it to completed when viewed.
        if (currentStep === FormSteps.REFERRAL) {
            if (!stepsCompleted.includes(FormSteps.REFERRAL)) {
                stepsCompleted.push(FormSteps.REFERRAL);
            }
        }
    }, [currentStep, stepsCompleted]);

    const referralStepColor =
        currentStep === FormSteps.REFERRAL ? AccordionColors.PINK : AccordionColors.GREY;
    const careStepColor =
        currentStep === FormSteps.CARE ? AccordionColors.PINK : AccordionColors.GREY;

    return {
        setFieldValue,
        setTouched,
        setValues,
        values,
        referralStepColor,
        careStepColor,
        onNextStep,
        onPatientDetailsNextStep,
        currentStep,
        setCurrentStep,
        stepsCompleted,
        setStepsCompleted,
        setStep,
    };
};
