import { ApolloError } from '@apollo/client';
import { Form, FormikValues } from 'formik';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { Accordion, AccordionColors, AccordionTab } from '../../../../v2/components';
import { FormMode, FormSteps } from '../common';
import { PullFromReferralForm } from '../DetailsFromReferral';
import { PatientTab } from '../PatientFormTab';
import CareDetails from './CareDetails';
import ReferralDetails from './ReferralDetails';
import { useAddVisitFormViewModel } from './useAddVisitFormViewModel';

export interface IProps {
    step: FormSteps;
    loading: boolean;
    error?: ApolloError;
    formMode: FormMode;
    onSubmit: (values: FormikValues) => Promise<void>;
}

const AddVisitForm: FC<IProps> = ({ step, loading, formMode, onSubmit }) => {
    const {
        setFieldValue,
        setValues,
        values,
        referralStepColor,
        careStepColor,
        onNextStep,
        onPatientDetailsNextStep,
        currentStep,
        stepsCompleted,
        setStep,
    } = useAddVisitFormViewModel({
        step,
        formMode,
        onSubmit,
    });

    return (
        <Form>
            <Accordion>
                <PatientTab
                    stepsCompleted={stepsCompleted}
                    currentStep={currentStep}
                    formMode={formMode}
                    loading={loading}
                    onClick={() => {
                        setStep(FormSteps.PATIENT);
                    }}
                    onNextStep={onPatientDetailsNextStep}
                />
                <AccordionTab
                    name="referral"
                    title="Referral details"
                    color={
                        stepsCompleted.includes(FormSteps.REFERRAL) &&
                        currentStep !== FormSteps.REFERRAL
                            ? AccordionColors.BLUE
                            : referralStepColor
                    }
                    open={currentStep === FormSteps.REFERRAL}
                    disabled={
                        !stepsCompleted.includes(FormSteps.PATIENT) ||
                        formMode === FormMode.FOLLOW_UP
                    }
                    onClick={() => {
                        if (stepsCompleted.includes(FormSteps.PATIENT)) {
                            setStep(FormSteps.REFERRAL);
                        }
                    }}
                >
                    {currentStep === FormSteps.REFERRAL && (
                        <ReferralDetails
                            values={values}
                            setFieldValue={setFieldValue}
                            loading={loading}
                            formMode={formMode}
                            onNextStep={onNextStep}
                        />
                    )}
                </AccordionTab>
                <AccordionTab
                    name="care"
                    title="Care details"
                    color={
                        stepsCompleted.includes(FormSteps.CARE) && currentStep !== FormSteps.CARE
                            ? AccordionColors.BLUE
                            : careStepColor
                    }
                    open={currentStep === FormSteps.CARE}
                    disabled={
                        !stepsCompleted.includes(FormSteps.PATIENT) ||
                        !stepsCompleted.includes(FormSteps.REFERRAL)
                    }
                    onClick={() => {
                        if (stepsCompleted.includes(FormSteps.PATIENT)) {
                            setStep(FormSteps.CARE);
                        }
                    }}
                >
                    {currentStep === FormSteps.CARE && (
                        <CareDetails
                            values={values}
                            loading={loading}
                            formMode={formMode}
                            setValues={setValues}
                            onNextStep={onNextStep}
                            setFieldValue={setFieldValue}
                        />
                    )}
                </AccordionTab>
            </Accordion>
            <PullFromReferralForm />
        </Form>
    );
};

export default observer(AddVisitForm);
