import { JobStatus, Pathway, Patient } from '@doc-abode/data-models';
import { FormikValues } from 'formik';
import moment from 'moment';
import { FC } from 'react';

import { getStringEndTime } from '../../../../../helpers';
// to prevent circular references we go direct.
import { shouldShowPostJobNotes } from '../../../../../helpers/shouldShowPostJobNotes';
import { isAborted, isDone } from '../../../../../helpers/statusCheckHelper';
import getHcpName from '../../../../../helpers/ucr/getHcpName';
import { getPathwayServiceOrAdminTypeLabels } from '../../../../../helpers/ucr/getPathwayServiceOrAdminTypeLabels';
import { transformFormikValuesToPatientForWarningValidation } from '../../../../../helpers/ucr/getWarnings';
import { IconAbort, IconAdd, IconChangeStatus, IconTick } from '../../../../../helpers/ucr/icons';
import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';
import { DialogAlerts, Dialogs } from '../../../../../stores/UCRStore';
import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import {
    Accordion,
    AccordionColors,
    AccordionTab,
    Button,
    ButtonColors,
    ButtonElems,
    ButtonSizes,
} from '../../../../v2/components';
import { Text } from '../../../../v2/form';
import { WarningBanner } from '../../../../v2/form/WarningBanner';
import { TimeHelperActualEnd, TimeHelperActualStart } from '../../blocks/panels/TimeHelper';
import { usePatientFlagsToChangeStatus } from '../../hooks/usePatientFlagsToChangeStatus';
import { useWarningMessageViewModel } from '../../hooks/useWarningMessageViewModel';
import { FormMode, FormSteps, friendlyCareComplexity } from '../common';
import PatientDetailsReviewForm from '../PatientDetails/PatientDetailsReviewForm';
import { VisitData } from './AddVisitTypes';
import { ReferralDetailsReview } from './ReferralDetailsReview';

interface Props {
    values: FormikValues;
    onSave?: () => Promise<void>;
    onEdit: (step: FormSteps) => void;
    patient?: Patient;
    formMode: string;
    isJobCreated: boolean;
}

interface careDetailsDoubleUpInterface {
    values: FormikValues;
    staffFiltered: string[];
    isSingleUp?: boolean;
}

interface startAndFinishedInterface {
    patient: Patient;
    isBuddy: boolean;
}

interface statusFieldInterface {
    arrivedAt?: string;
    finishedAt?: string;
    notes?: string;
    completed: boolean;
    hideNotes?: boolean;
    staffMember: string;
    status: string;
    memberName: string;
    aborted: boolean;
    isBuddy: boolean;
    values: FormikValues;
    codAborted: boolean;
}

const ReviewForm: FC<Props> = ({ values, onSave, onEdit, formMode, patient, isJobCreated }) => {
    const {
        RootStore: {
            usersStore: { users },
            configStore: { pathways },
            ucrStore: {
                openedDialogAlert,
                setOpenedDialogAlert,
                setOpenedDialog,
                setAbortVisit,
                setFollowUpVisitData,
                setFollowUpAdminTimeData,
                setLocalPatientData,
            },
        },
    } = useStores<{ RootStore: RootStore }>();

    const { isDoubleUp, isDisabled } = usePatientFlagsToChangeStatus(patient);
    const isSaveEnabled =
        formMode === FormMode.DEFAULT ||
        formMode === FormMode.ADD_VISIT ||
        formMode === FormMode.FOLLOW_UP;

    if (openedDialogAlert !== DialogAlerts.SAVE_VISIT && isSaveEnabled) {
        setOpenedDialogAlert(DialogAlerts.SAVE_VISIT);
    }

    // Wrapping in an array to keep changes to downstream dependencies minimal
    const staffFiltered = [
        getHcpName(users, values.hcpId, false),
        getHcpName(users, values.buddyId, false),
    ];

    const isHCPAborted = isAborted({ jobStatus: values?.jobStatus });
    const isBuddyAborted = isAborted({ jobStatus: values?.buddyJobStatus });

    const isJobAborted = isDoubleUp ? isHCPAborted && isBuddyAborted : isHCPAborted;

    const isHCPDone = isDone({ jobStatus: values?.jobStatus });
    const isBuddyDone = isDone({ jobStatus: values?.buddyJobStatus });

    const canShowAbortButton = isDoubleUp ? !isHCPDone || !isBuddyDone : !isHCPDone;

    const isAbortedMustShow =
        (isHCPAborted || isBuddyAborted) && (values.arrivedDateTime || values.buddyArrivedDateTime);
    const onCancelVisit = () => {
        if (patient) {
            setAbortVisit(patient);
        }
    };

    // Create a temporary job to allow reuse of validations
    const tmpJobForValidation = transformFormikValuesToPatientForWarningValidation({
        values,
        isAdmin: false,
    });

    const { warningMessages, hasWarningMessages } = useWarningMessageViewModel({
        job: tmpJobForValidation,
    });

    return (
        <Accordion>
            <AccordionTab
                name="patients"
                title="Patient details"
                onEdit={!isJobAborted ? () => onEdit(FormSteps.PATIENT) : undefined}
                color={AccordionColors.PINK}
                open
            >
                <PatientDetailsReviewForm values={values} />
            </AccordionTab>
            <AccordionTab
                name="referral"
                title="Referral details"
                onEdit={!isJobAborted ? () => onEdit(FormSteps.REFERRAL) : undefined}
                color={AccordionColors.PINK}
                open
            >
                <ReferralDetailsReview values={values} />
            </AccordionTab>
            <AccordionTab
                name="care"
                title="Care details"
                onEdit={
                    !isJobAborted || isAbortedMustShow ? () => onEdit(FormSteps.CARE) : undefined
                }
                color={AccordionColors.PINK}
                open
            >
                <CareDetails values={values} staffFiltered={staffFiltered} pathways={pathways} />
            </AccordionTab>
            <ConditionalDisplay show={hasWarningMessages}>
                <div className="v2__form-group--pos-1-1">
                    <WarningBanner spacerTop={true} compact={true} warnings={warningMessages} />
                </div>
            </ConditionalDisplay>
            <div className="v2__form-btn-section v2__form-btn-section--mb">
                {(formMode === FormMode.DEFAULT ||
                    formMode === FormMode.ADD_VISIT ||
                    formMode === FormMode.FOLLOW_UP) && (
                    <Button
                        className="v2__form-submit-button"
                        elem={ButtonElems.DIV}
                        name="Save visit"
                        Icon={IconTick}
                        size={ButtonSizes.MEDIUM}
                        clickEvent={() => {
                            if (onSave) onSave();
                            setLocalPatientData();
                        }}
                    />
                )}
                {patient?.jobStatus && (
                    <Button
                        className="v2__form-submit-button"
                        name="Create follow-up visit"
                        Icon={IconAdd}
                        clickEvent={() => {
                            const visitValues: VisitData = values as VisitData;
                            setFollowUpVisitData(visitValues);
                            setOpenedDialog(Dialogs.ADD_NEW_VISIT);
                        }}
                    />
                )}

                {patient?.jobStatus && (
                    <Button
                        className="v2__form-submit-button"
                        name="Create admin task"
                        Icon={IconAdd}
                        clickEvent={() => {
                            const visitValues: Partial<VisitData> = values;
                            setFollowUpAdminTimeData(visitValues);
                            setOpenedDialog(Dialogs.ADMINISTRATIVE_TIME);
                        }}
                    />
                )}
                {isDisabled ? (
                    <Button
                        className="v2__form-submit-button"
                        size={ButtonSizes.MEDIUM}
                        name="Change status"
                        Icon={IconChangeStatus}
                        clickEvent={() => setOpenedDialog(Dialogs.CHANGE_VISIT_STATUS)}
                    />
                ) : null}
                {canShowAbortButton && isJobCreated && formMode !== 'default' && (
                    <Button
                        className="v2__form-submit-button"
                        size={ButtonSizes.MEDIUM}
                        elem={ButtonElems.BUTTON}
                        color={ButtonColors.RED}
                        name="Abort job"
                        Icon={IconAbort}
                        clickEvent={onCancelVisit}
                    />
                )}
            </div>
        </Accordion>
    );
};

export default ReviewForm;

const StartAndFinishedDates: FC<startAndFinishedInterface> = ({
    patient,
    isBuddy,
}): JSX.Element => {
    return (
        <>
            <Text
                name="arrivedDateTime"
                title="Actual start time"
                notSetText="Not yet recorded"
                description={TimeHelperActualStart({ patient: patient, isFirstUser: !isBuddy })}
            />
            <Text
                name="finishedDateTime"
                title="Actual end time"
                notSetText="Not yet recorded"
                description={TimeHelperActualEnd({ patient: patient, isFirstUser: !isBuddy })}
            />{' '}
        </>
    );
};

const StatusFields: FC<statusFieldInterface> = ({
    arrivedAt,
    finishedAt,
    staffMember,
    status,
    memberName,
    aborted,
    isBuddy,
    values,
    codAborted,
}): JSX.Element => {
    const patient: Patient = values as Patient;

    return (
        <>
            <Text
                name="staffMember"
                title={`Staff member ${staffMember}`}
                notSetText="Not yet recorded"
                description={memberName}
            />
            <Text
                name="Status"
                title={`Status`}
                notSetText="Not yet recorded"
                description={status}
            />
            <StartAndFinishedDates patient={patient} isBuddy={isBuddy} />

            {isBuddy && aborted && (
                <>
                    <Text
                        name="buddyAborted"
                        title="Aborted reason (HCP)"
                        description={values.buddyHcpAbortedReason}
                    />
                    <Text
                        name="buddyAbortedNotes"
                        title="Aborted Notes (HCP)"
                        description={values.buddyHcpAbortedNotes}
                    />
                </>
            )}

            {isBuddy && codAborted && (
                <>
                    <Text
                        name="buddyAborted"
                        title="Aborted reason (COD)"
                        description={values.buddyControllerAbortedReason}
                    />
                    <Text
                        name="buddyAborted"
                        title="Aborted Notes (COD)"
                        description={values.buddyControllerAbortedNotes}
                    />
                </>
            )}

            {!isBuddy && aborted && (
                <>
                    <Text
                        name="aborted"
                        title="Aborted reason (HCP)"
                        description={values.hcpAbortedReason}
                    />
                    <Text
                        name="abortedNotes"
                        title="Aborted Notes (HCP)"
                        description={values.hcpAbortedNotes}
                    />
                </>
            )}
            {!isBuddy && codAborted && (
                <>
                    <Text
                        name="abortedCod"
                        title="Aborted reason (COD)"
                        description={values.controllerAbortedReason}
                    />
                    <Text
                        name="abortedCodNotes"
                        title="Aborted Notes (COD)"
                        description={values.controllerAbortedNotes}
                    />
                </>
            )}
        </>
    );
};

const CareDetailsDoubleUp: FC<careDetailsDoubleUpInterface> = ({
    values,
    staffFiltered,
    isSingleUp,
}): JSX.Element => {
    const statusOfJobHcp1 = values?.jobStatus as JobStatus | undefined;
    const statusOfJobHcp2 = values?.buddyJobStatus as JobStatus | undefined;

    const isCompleted = values?.jobStatus === JobStatus.COMPLETED;
    const isBuddyCompleted = values?.buddyJobStatus === JobStatus.COMPLETED;
    const isAborted = values?.jobStatus === JobStatus.HCP_ABORTED;
    const isBuddyAborted = values?.buddyJobStatus === JobStatus.HCP_ABORTED;
    const isControllerAborted = values?.jobStatus === JobStatus.CONTROLLER_ABORTED;
    const isBuddyControllerAborted = values?.buddyJobStatus === JobStatus.CONTROLLER_ABORTED;
    const friendlyStatus = Patient.getFriendlyVisitStatus(values.jobStatus);
    const buddyStatus = Patient.getFriendlyVisitStatus(values.buddyJobStatus);

    const showHcp1PostNotes = shouldShowPostJobNotes({ jobStatus: statusOfJobHcp1 });
    const showHcp2PostNotes = shouldShowPostJobNotes({ jobStatus: statusOfJobHcp2 });

    return (
        <div className="v2__form-block">
            <div className={isSingleUp ? 'v2__form-group--pos-1-1' : 'v2__form-group--pos-1-2'}>
                <StatusFields
                    completed={isCompleted}
                    status={friendlyStatus}
                    staffMember={'#1'}
                    arrivedAt={values.arrivedDateTime}
                    finishedAt={values.finishedDateTime}
                    memberName={staffFiltered[0]}
                    aborted={isAborted}
                    codAborted={isControllerAborted}
                    isBuddy={false}
                    values={values}
                />
                {showHcp1PostNotes && (
                    <Text
                        name="postVisitNotes"
                        title={isSingleUp ? 'Post-job notes' : 'Post-job notes #1'}
                        className="visit-details__notes"
                        description={values.postVisitNotes}
                    />
                )}
            </div>
            {!isSingleUp && (
                <div className="v2__form-group--pos-2-2">
                    <StatusFields
                        completed={isBuddyCompleted}
                        status={buddyStatus}
                        staffMember={'#2'}
                        arrivedAt={values.buddyArrivedDateTime}
                        finishedAt={values.buddyFinishedDateTime}
                        memberName={staffFiltered[1]}
                        isBuddy={true}
                        aborted={isBuddyAborted}
                        codAborted={isBuddyControllerAborted}
                        values={values}
                    />
                    {showHcp2PostNotes && (
                        <Text
                            name="postVisitNotesBuddy"
                            title="Post-job notes #2"
                            className="visit-details__notes"
                            description={values.postVisitNotesBuddy}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

function CareDetails({
    values,
    staffFiltered,
    pathways,
}: {
    values: FormikValues;
    staffFiltered: string[];
    pathways: Pathway[];
}) {
    const { pathwayDisplayLabel, serviceOrAdminTypeDisplayLabel } =
        getPathwayServiceOrAdminTypeLabels(values as Patient, pathways, []);

    return (
        <>
            <div className="v2__form-block">
                <Text
                    name="referralPathway"
                    title="Pathway"
                    description={pathwayDisplayLabel}
                    className="v2__form-group--pos-1-2"
                />
                <Text
                    name="Service required"
                    title="Service required"
                    description={serviceOrAdminTypeDisplayLabel}
                    className="v2__form-group--pos-2-2"
                />
                <Text
                    name="notes"
                    title="Pre-job notes"
                    className="visit-details__notes v2__form-group--pos-1-1"
                    description={values.notes}
                />
                <Text
                    name="visitDate"
                    title="Planned date of visit"
                    description={moment(values.visitDate).format('dddd Do MMMM YYYY')}
                    className="v2__form-group--pos-1-4"
                />
                <Text
                    name="startTime"
                    title="Planned start time"
                    description={values.startTime ? moment(values.startTime).format('h:mm a') : ''}
                    className="v2__form-group--pos-2-4"
                />
                <Text
                    name="duration"
                    title="Planned duration"
                    description={values.duration}
                    className="v2__form-group--pos-3-4"
                />
                <Text
                    name="endTime"
                    title="Expected end time"
                    description={
                        values.startTime
                            ? getStringEndTime(values.startTime, values.duration, 'hh:mm a')
                            : ''
                    }
                    className="v2__form-group--pos-4-4"
                />
            </div>
            <CareDetailsDoubleUp
                isSingleUp={values.staffRequired !== 2}
                values={values}
                staffFiltered={staffFiltered}
            />
            <div className="v2__form-block">
                <Text
                    name="careComplexity"
                    title="Care complexity"
                    description={
                        values.careComplexity ? friendlyCareComplexity[values.careComplexity] : ''
                    }
                    className="v2__form-group--pos-1-2"
                />
                <Text
                    name="carRequired"
                    title="Car required"
                    description={values.carRequired ? 'Yes' : 'No'}
                    className="v2__form-group--pos-2-2"
                />
                <Text
                    name="earliestDateOfVisit"
                    title="Earliest date of visit"
                    description={
                        values.earliestDateOfVisit
                            ? moment(values.earliestDateOfVisit).format('dddd Do MMMM YYYY')
                            : ''
                    }
                    className="v2__form-group--pos-1-4"
                />
                <Text
                    name="availableFrom"
                    title="Earliest time of visit"
                    description={
                        values.availableFrom ? moment(values.availableFrom).format('h:mm a') : ''
                    }
                    className="v2__form-group--pos-2-4"
                />
                <Text
                    name="availableTo"
                    title="Latest time of visit"
                    description={
                        values.availableTo ? moment(values.availableTo).format('h:mm a') : ''
                    }
                    className="v2__form-group--pos-3-4"
                />
            </div>
        </>
    );
}
