import { LiveLocationThreshold } from '@doc-abode/data-models';
import moment from 'moment';

import { MarkerData } from '../../../../../interfaces/map';
import { IHcpExtended } from '../../../../../interfaces/ucr';
import { IHCPBreakdown } from '../../../../../stores/MapStoreTypes';
import { getHcpTooltip } from './getTooltipForMaps';
import { Markers } from './Markers';

/**
 * Helper to check whether HCP live locations should be shown on Map View
 * @param isFeatureEnabled value of the relevant feature flag
 * @param selectedDate the currently selected calendar date
 * @returns boolean
 */
export function shouldShowLiveLocationsOnMap(
    isFeatureEnabled: boolean,
    selectedDate: Date,
): boolean {
    return isFeatureEnabled && moment(selectedDate).isSame(moment(), 'day');
}

/**
 * Helper to check whether a HCP can be shown on Map View
 * @param hcp the HCP to be checked
 * @returns boolean
 */
export function canShowHcpLiveLocationOnMap(hcp: IHcpExtended): boolean {
    return Boolean(
        hcp.enabled && hcp.available && hcp.latitude && hcp.longitude && hcp.lastKnownLocationTime,
    );
}

/**
 * Helper to return an array of HCPs for whom to show live locations on Map
 * @param hcps the list of potential HCPs
 * @param activeHcpsOnSelectedDate the list of currently displayed HCPs
 * @param liveLocationConfig configuration params driving live location
 * @returns IHcpExtended
 */
export function getHcpsWithQualifyingLiveLocations(
    hcps: IHcpExtended[],
    activeHcpsOnSelectedDate: Record<string, IHCPBreakdown[]>,
    liveLocationConfig: LiveLocationThreshold,
) {
    const gracePeriodInMinutes = liveLocationConfig.gracePeriodMinutes || 0;

    return hcps.filter(
        (hcp) =>
            !!activeHcpsOnSelectedDate[hcp.userId] &&
            canShowHcpLiveLocationOnMap(hcp) &&
            moment(hcp.lastKnownLocationTime).isAfter(
                moment(moment().startOf('day')).subtract(gracePeriodInMinutes, 'minute'),
            ),
    );
}

/**
 * Helper to get the correct colour for the HCP live location marker
 * @param hcp the HCP to check for
 * @param liveLocationConfig relevant configuration for the org
 * @returns
 */
export function getHcpLiveLocationMarkerFillColour(
    hcp: IHcpExtended,
    liveLocationConfig: LiveLocationThreshold,
) {
    const timeThreshold = liveLocationConfig.value ?? 0;
    const colourAboveThreshold = liveLocationConfig.color?.above ?? 'dda0dd';
    const colourBelowThreshold = liveLocationConfig.color?.below ?? '834277';

    const minutesPassed = moment().diff(moment(hcp.lastKnownLocationTime), 'minutes');

    return minutesPassed > timeThreshold ? colourAboveThreshold : colourBelowThreshold;
}

/**
 * Helper to extract relevant data points to render the live location of an HCP
 * @param hcp the HCP to check for
 * @param liveLocationConfig relevant configuration for the org
 * @returns
 */
export function prepareMarkerDataForHcpLiveLocation(
    hcp: IHcpExtended,
    liveLocationConfig: LiveLocationThreshold,
): MarkerData {
    const latitude = hcp.latitude ?? 0;
    const longitude = hcp.longitude ?? 0;

    return {
        type: 'hcp',
        latitude,
        longitude,
        url: `data:image/svg+xml;charset=UTF-8;base64, ${btoa(
            Markers.singleHcp({
                fill: '#' + getHcpLiveLocationMarkerFillColour(hcp, liveLocationConfig),
                label: hcp.firstName.charAt(0).toUpperCase() + hcp.lastName.charAt(0).toUpperCase(),
            }),
        )}`,
        title: getHcpTooltip(hcp),
        key: `hcp-marker-${hcp.userId}`,
        clickable: false,
    };
}
