import { formatPdsRecord, FormattedPDSRecord, pdsRecordSchema } from '@doc-abode/data-models';
import { fromError } from 'zod-validation-error';

import { baseUrl, request } from './baseApi';

/**
 * Gets the PDS spine data for a patient
 * @param {string} nhsNumber - The NHS number of the patient
 * @param {boolean} retry - Whether to retry the request if it fails
 */
export const getPDSSpineData = async (
    nhsNumber: string,
    retry: boolean = false,
): Promise<FormattedPDSRecord> => {
    try {
        const response = await request(
            {
                url: `${baseUrl}/nhs-digital/pds-fhir/patient/${nhsNumber}`,
                method: 'GET',
            },
            retry,
        );

        const responseParseAttempt = pdsRecordSchema.safeParse(response);
        if (!responseParseAttempt.success) {
            throw new Error('Failed to parse response', fromError(responseParseAttempt.error));
        }

        const data = responseParseAttempt.data;

        const formattedData: FormattedPDSRecord = formatPdsRecord(data, true);
        return formattedData;
    } catch (e: any) {
        console.error('PDS lookup error', e);

        const errorMessage = e.response?.data?.issue?.[0]?.details?.coding?.[0]?.display;
        throw new Error(errorMessage || 'Failed to retrieve PDS data');
    }
};
