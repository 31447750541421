import moment from 'moment';

import { FormFieldRecord } from '../jobCreator';

export const referralSchema: FormFieldRecord[] = [
    { key: 'systmOneRef', followUpCopy: ['ADMIN', 'VISIT'] },
    {
        key: 'referrer',
        followUpCopy: ['ADMIN', 'VISIT'],
        transformValue: (values) => {
            return values.referrer ?? null;
        },
    },
    {
        key: 'referralDateTime',
        followUpCopy: ['ADMIN', 'VISIT'],
        transformValue: (values) => {
            if (values.referralDateTime) {
                return moment(values.referralDateTime).toISOString();
            }
            return null;
        },
    },
];
