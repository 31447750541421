import { useMutation } from '@apollo/client';
import { Patient } from '@doc-abode/data-models';
import { Formik, FormikValues } from 'formik';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { FC, useContext, useEffect, useState } from 'react';

import { CREATE_JOB } from '../../../../../graphql/queries/jobs';
import {
    getStartDateForDefaultVisitAndAdmin,
    getStartDateForFollowUp,
} from '../../../../../helpers/getStartDateForFollowUp';
import { jobCreator, valueType } from '../../../../../helpers/jobCreator/jobCreator';
import useStores from '../../../../../hook/useStores';
import { JobsContext } from '../../../../../providers';
import RootStore from '../../../../../stores/RootStore';
import { DialogAlerts, Dialogs } from '../../../../../stores/UCRStore';
import AppToaster from '../../../../modules/helpers/Toaster';
import { useView } from '../../views/useView';
import { FormMode, FormSteps } from '../common';
import AddVisitForm from './AddVisitForm';
import { VisitData } from './AddVisitTypes';
import ReviewForm from './ReviewForm';
import validationSchema from './validation';

const AddVisit: FC = () => {
    const [createJob, { loading, error }] = useMutation(CREATE_JOB);

    const {
        RootStore: {
            configStore: { org },
            userStore: {
                user: { username },
            },
            usersStore: { users },
            ucrStore: {
                followUpVisitData,
                setFollowUpVisitData,
                setOpenedDialog,
                setOpenedDialogAlert,
                selectedDate,
            },
        },
    } = useStores<{ RootStore: RootStore }>();
    const [isJobCreated, setIsJobCreated] = useState<boolean>(false);

    const jobsContext = useContext(JobsContext);

    const { currentViewState } = useView();

    const [formData, setFormData] = useState<{
        step: FormSteps;
        values?: VisitData | null;
        initialValues?: VisitData | null;
        formMode: FormMode;
    }>({
        step: followUpVisitData ? FormSteps.CARE : FormSteps.PATIENT,
        formMode: followUpVisitData ? FormMode.FOLLOW_UP : FormMode.DEFAULT,
    });

    // this really needs to be typed better.
    // removing buddy status times as if followUpVisitData
    // doesn't exist then how could it be possible to fetch these values.

    let followUpData: Record<string, valueType> | undefined = undefined;

    if (followUpVisitData) {
        followUpData = jobCreator({
            values: {
                ...(followUpVisitData as unknown as any),
                ...{
                    _additionalValues: {
                        org,
                        username,
                        id: undefined,
                        users,
                    },
                },
            },
            onCreate: true,
            followUp: true,
            type: 'VISIT',
        });
    }

    const initialData: any = followUpData ||
        formData.initialValues || {
            ...Patient.INITIAL_VALUES,
            buddyMadeCurrentDateTime: null,
            buddyArrivedDateTime: null,
            buddyFinishedDateTime: null,
            referralDateTime: undefined,
            dateOfBirth: new Date(),
            visitDate: getStartDateForDefaultVisitAndAdmin({
                systemTime: selectedDate,
                fromPatientListView: currentViewState.patientList,
            }),
            availableFrom: null,
            availableTo: null,
            startTime: null,
            staffPreferredGender: [],
            duration: '01:00',
        };

    delete initialData.buddyHcpGrade;
    delete initialData.buddyHcpType;

    if (followUpVisitData) {
        initialData.visitDate = getStartDateForFollowUp({
            systemTime: selectedDate,
            dateOfVisit: moment(
                followUpVisitData.startDateTime || followUpVisitData.visitDate,
            ).toDate(),
            fromPatientListView: currentViewState.patientList,
        });
    }

    const onSubmit = async (values: FormikValues) => {
        const newValues = {
            ...(cloneDeep(values) as VisitData),
            contactNumber: values?.contactNumber ? values?.contactNumber : undefined,
            additionalContactNumbers: values?.additionalContactNumbers
                ? values?.additionalContactNumbers.filter((str: string) => str)
                : undefined,
        };

        setFormData((data) => ({
            ...data,
            values: newValues,
        }));
    };

    const saveVisitData = async () => {
        if (!formData.values) return;

        const formattedData = jobCreator({
            values: {
                ...(formData.values as unknown as any),
                ...{
                    _additionalValues: {
                        org,
                        username,
                        id: undefined,
                        users,
                    },
                },
            },
            onCreate: true,
            type: 'VISIT',
        });

        try {
            await createJob({ variables: { input: formattedData } });
            setIsJobCreated(true);
        } catch (err) {
            console.log('Error creating visit', err);
            AppToaster.show({
                message: 'Sorry, an error occurred and we were unabled to create the visit',
                intent: 'danger',
            });
            return;
        }

        AppToaster.show({
            message: 'Visit added successfully!',
            intent: 'success',
        });

        setFormData((data) => ({
            ...data,
            initialValues: null,
        }));
        setFollowUpVisitData(null);

        setOpenedDialog(Dialogs.NONE);

        if (!currentViewState.patientList) {
            const isUnassigned =
                (formData.values?.staffRequired !== 2 && !formData.values?.hcpId) ||
                (formData.values?.staffRequired === 2 &&
                    !formData.values?.hcpId &&
                    !formData.values?.buddyId);

            if (
                isUnassigned ||
                moment(formData.values?.visitDate).isSame(moment(selectedDate), 'day')
            ) {
                jobsContext.setRefreshAssignedJobs(true);
            }
        } else {
            jobsContext.setRefreshPatients(true);
        }
    };

    const onEditReviewForm = (step: FormSteps) => {
        setOpenedDialogAlert(DialogAlerts.SAVE_VISIT);
        setFormData((data) => ({
            step,
            initialValues: cloneDeep(data.values),
            formMode: FormMode.ADD_VISIT,
        }));
    };

    useEffect(() => {
        setOpenedDialogAlert(!formData.values ? DialogAlerts.SAVE_VISIT : DialogAlerts.NONE);
    }, [formData.values, setOpenedDialogAlert]);

    useEffect(() => {
        return () => setFollowUpVisitData(null);
    });

    return (
        <Formik initialValues={initialData} validationSchema={validationSchema} onSubmit={onSubmit}>
            {formData.values ? (
                <ReviewForm
                    values={formData.values}
                    formMode={formData.formMode}
                    onEdit={onEditReviewForm}
                    onSave={saveVisitData}
                    isJobCreated={isJobCreated}
                />
            ) : (
                <AddVisitForm
                    step={formData.step}
                    loading={loading}
                    error={error}
                    formMode={formData.formMode}
                    onSubmit={onSubmit}
                />
            )}
        </Formik>
    );
};

export default AddVisit;
