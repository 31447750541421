import * as Sentry from '@sentry/react';
import forEach from 'lodash/forEach';
import { action, computed, makeObservable, observable } from 'mobx';
import ReactGA from 'react-ga4';

import { getUserCurrentSession, userSignOut } from '../api/amplifyApi';
import { getUser } from '../api/usersApi';
import RootStore from './RootStore';

interface OrganisationInfo {
    name: string;
    value: string;
}

class UserStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this, {
            user: observable,
            isLoading: observable,
            redirectToHome: observable,
            organisationsInfo: observable,
            isComplianceOfficer: computed,
            userOrganisations: computed,
            isSuperuser: computed,
            isAdmin: computed,
            isController: computed,
            isHcp: computed,
            updateUser: action,
            getUserSession: action,
            authorize: action,
            logOut: action,
            setRedirectToHome: action,
            getUserInfo: action,
        });

        this.rootStore = rootStore;
    }

    user: any = {
        tokens: {
            access: '',
            id: '',
            refresh: '',
        },
        userGroups: [],
        username: '',
        email: '',
        organisations: [],
        adminOrganisations: [],
        controllerOrganisations: [],
        hcpOrganisations: [],
        superuserOrganisations: [],
    };

    organisationsInfo: OrganisationInfo[] = [];
    isLoading = false;
    redirectToHome = false;

    get userOrganisations() {
        return [...this.user.adminOrganisations, ...this.user.controllerOrganisations];
    }

    updateUser = (user: any) => {
        Object.assign(this.user, user);

        const org = this.rootStore.configStore.checkOrganisation(this.userOrganisations);
        Sentry.getCurrentScope().setUser({ username: user.username }).setTag('org', org);
        ReactGA.set({ userId: user.sub });
        this.getUserInfo();
    };

    getUserSession = async (): Promise<any> => {
        try {
            const userSession = await getUserCurrentSession();
            const userGroups: string[] = userSession.idToken.payload['cognito:groups'];

            return {
                tokens: {
                    access: userSession.accessToken.jwtToken,
                    id: userSession.idToken.jwtToken,
                    refresh: userSession.refreshToken.token,
                },
                userGroups,
                username: userSession.idToken.payload['cognito:username'],
                sub: userSession.idToken.payload.sub,
                email: userSession.idToken.payload.email,
                emailVerified: userSession.idToken.payload.email_verified,
                phoneNumber: userSession.idToken.payload.phone_number,
                phoneNumberVerified: userSession.idToken.payload.phone_number_verified,
                organisations: userGroups
                    .filter((group) => group.startsWith('org-'))
                    .map((group) => group.replace('org-', '')),
                superuserOrganisations: userGroups
                    .filter((group) => group.startsWith('superuser-'))
                    .map((group) => group.replace('superuser-', '')),
                adminOrganisations: userGroups
                    .filter((group) => group.startsWith('org_admin-'))
                    .map((group) => group.replace('org_admin-', '')),
                controllerOrganisations: userGroups
                    .filter((group) => group.startsWith('controller-'))
                    .map((group) => group.replace('controller-', '')),
                hcpOrganisations: userGroups
                    .filter((group) => group.startsWith('hcp-'))
                    .map((group) => group.replace('hcp-', '')),
            };
        } catch (error) {
            if (error && error !== 'No current user') {
                console.error(error);
            }
            return null;
        }
    };

    getAuthToken = async () => {
        const userSession = await this.getUserSession();
        return userSession?.tokens.id as string;
    };

    getUserInfo = async () => {
        const userSession = await getUserCurrentSession();
        const user: any = await getUser(
            userSession.accessToken.payload.username,
            userSession.idToken.jwtToken,
        );

        if (!user.organisations) return;
        if (!Object.keys(user.organisations)?.length) return;

        const organisationsInfo: OrganisationInfo[] = [];

        forEach(user.organisations, ({ name }, key) => {
            if (this.user.organisations.includes(key)) {
                organisationsInfo.push({ name, value: key });
            }
        });

        this.organisationsInfo = organisationsInfo;
    };

    get isSuperuser() {
        return this.user.superuserOrganisations.includes(this.rootStore.configStore.org);
    }

    get isAdmin() {
        return this.user.adminOrganisations.includes(this.rootStore.configStore.org);
    }

    get isController() {
        return this.user.controllerOrganisations.includes(this.rootStore.configStore.org);
    }

    get isHcp() {
        return this.user.hcpOrganisations.includes(this.rootStore.configStore.org);
    }

    authorize = async () => {
        this.isLoading = true;
        const user = await this.getUserSession();

        if (user !== null) {
            this.updateUser(user);
        }

        this.isLoading = false;
    };

    logOut = async () => {
        try {
            await userSignOut();
            window.location.assign('/');
        } catch (error) {
            console.log(error);
        }
    };

    get isComplianceOfficer() {
        return this.user.userGroups.includes('compliance_officer');
    }

    setRedirectToHome = (value: boolean) => {
        this.redirectToHome = value;
    };
}

export default UserStore;
